import "./App.css";
import PrayerTimesTest from "./PrayerTimesTest";

function App() {
  return (
    <div className="App">
      <PrayerTimesTest />
    </div>
  );
}

export default App;
